<template>
    <Modal :modal-title="title"
           :dialog="cityFormVisibility"
           max-width="600px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ManageCityForm ref="form"
                            :is-editable="isEditable"></ManageCityForm>
            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>

            <span data-test="testKeydownSpan"></span>

            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ManageCityForm from '@/components/cities/manageCity/ManageCityForm';
import ValidationError from '@/components/widgets/ValidationError';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';

export default {
    name: 'ManageCity',
    components: { Modal, ManageCityForm, ValidationError, ConfirmationDialog },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapState('cities', [
            'cityFormVisibility',
            'activeCityId'
        ]),
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        title () {
            return this.isEditable ? this.$t('configurations.cities.edit_title') : this.$t('configurations.cities.add_title');
        },
        isEditable () {
            return this.activeCityId !== null && this.activeCityId !== undefined;
        }
    },
    methods: {
        close () {
            this.$store.commit('cities/SET_CITY_FORM_VISIBILITY', false);
            this.$refs.form.resetValidation();
            // prevent showing bad data 0.1s
            setTimeout(() => {
                this.$store.commit('cities/RESET_CITY_DATA');
                this.$store.commit('cities/SET_ACTIVE_CITY_ID', null);
            }, 100);
        },
        visibility (event) {
            this.$store.commit('cities/SET_CITY_FORM_VISIBILITY', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        createCity () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('cities/createCity')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updateCity () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('cities/updateCity')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.isEditable) {
                this.updateCity();
            } else {
                this.createCity();
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
