<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs"
                            tag="div"
                            class="px-4">
            <Autocomplete :label="$t('labels.country')"
                          name="countryId"
                          rules="required"
                          clearable
                          :items="countries"
                          :loading="isCountriesLoading"
                          :search-input.sync="searchCountries"
                          @click:clear="getCountries"
                          @load-more-items="getCountriesLazyLoading"
                          v-model="countryId"></Autocomplete>
            <TextField :label="$t('labels.zip_code')"
                       name="zipCode"
                       rules="required"
                       v-model="zipCode"></TextField>
            <TextField :label="$t('labels.city_name')"
                       name="name"
                       rules="required"
                       v-model="name"></TextField>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import TextField from '@/components/widgets/forms/TextField';
import debouncedCountries from '@/mixins/debounced/debouncedCountries';

export default {
    name: 'ManageCityForm',
    mixins: [debouncedCountries],
    components: { ValidationObserver, Autocomplete, TextField },
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapState('generalConfigurations', [
            'countries'
        ]),
        ...mapState('cities', [
            'cityFormVisibility',
            'activeCityId'
        ]),
        ...mapFields('cities', [
            'cityData.countryId',
            'cityData.zipCode',
            'cityData.name'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    watch: {
        cityFormVisibility: {
            handler (val) {
                if (val && this.isEditable && this.countryId !== null) {
                    this.getCountries({
                        'filter[id]': this.countryId
                    });
                }
            },
            immediate: true
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped>

</style>
