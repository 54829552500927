<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.cities') }}</h1>
        <FiltersBar ref="filtersBar"></FiltersBar>

        <CitiesTable @change-page="changePage($event)"
                     @change-amount="changeAmount($event)"
                     @sort="sortingColumn = $event"
                     :loader="loader"
                     @fetch="fetchCities"
                     :changed-page="pagination.page"></CitiesTable>

        <ManageCity @fetch="fetchCities"></ManageCity>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import CitiesTable from '@/components/cities/CitiesTable';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import ManageCity from '@/components/cities/manageCity/ManageCity';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import debouncedCountries from '@/mixins/debounced/debouncedCountries';
import filtering from '@/mixins/filtering';

export default {
    name: 'AreaConfigurations',
    mixins: [debouncedCountries, filtering],
    components: { CitiesTable, FiltersBar, ManageCity },
    data: () => ({
        debouncedFetchCities: null,
        pagination: {
            page: 1,
            amount: 20
        },
        loader: false,
        sortingColumn: null
    }),
    computed: {
        ...mapFields('search', [
            'search'
        ]),
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.COUNTRY_ID,
                    label: this.$t('labels.country'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.COUNTRY,
                    lazy: this.getCountriesLazyLoading,
                    searchCallback: this.debounceGetCountries,
                    items: {
                        obj: 'state',
                        module: 'generalConfigurations',
                        prop: 'countries'
                    }
                })
            ];
        }
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchCities();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debouncedFetchCities();
                }
            }
        }
    },
    methods: {
        fetchCities () {
            this.loader = true;

            const params = {
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                ...this.generateFilters()
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params['filter[search][match]'] = this.search;
            }

            this.$store.dispatch('cities/getCities', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        generateFilters () {
            const params = {};

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    params[`filter[${f}]`] = this.filters[f].join(',');
                }
            });

            return params;
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchCities();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchCities();
        }
    },
    created () {
        this.debouncedFetchCities = debounce(this.fetchCities, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchCities);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.CITY)) {
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('generalConfigurations/getCountries')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.COUNTRY_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.fetchCities();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
