<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout justify-end
                  class="mb-8">
            <v-btn color="secondary"
                   v-if="canAddCity"
                   @click="openForm"
                   data-test="addNewIncidentBtn">{{ $t('buttons.add_new_city') }}</v-btn>
        </v-layout>
        <v-data-table :headers="headers"
                      :loading="loader"
                      disable-sort
                      :items="rawCities"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>

            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.EDIT"
                             v-if="canEditCity"
                             @click="openForm(item)"
                             :data-test="item"
                             :tooltip="$t('actions.edit')"></HintingIcon>
                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canDeleteCity && 0 === 1"
                                        :callback="deleteCity"></ButtonWithConfirmation>
            </template>
        </v-data-table>

        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HintingIcon from '@/components/widgets/HintingIcon';
import Pagination from '@/components/widgets/tables/Pagination';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';

export default {
    name: 'CitiesTable',
    components: { SortableHeader, HintingIcon, Pagination, ButtonWithConfirmation },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: () => ({
        ICONS,
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        sortable: {
            name: 'asc'
        }
    }),
    computed: {
        ...mapState('cities', [
            'rawCities',
            'totalCitiesAmount'
        ]),
        ...mapState('locale', [
            'currentLocale'
        ]),
        countryName () {
            return this.currentLocale === 'pl' ? 'country_name_pl' : 'country_name_en';
        },
        headers () {
            return [
                {
                    text: this.$t('labels.zip_code'),
                    value: 'zip_code',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.city_name'),
                    value: 'name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.country'),
                    value: this.countryName,
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('incidents.table.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '90px',
                    sortable: false
                }
            ];
        },
        canEditCity () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.CITY);
        },
        canDeleteCity () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.CITY);
        },
        canAddCity () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.CITY);
        }
    },
    watch: {
        totalCitiesAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        }
    },
    methods: {
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        },
        openForm (item = null) {
            if (item) {
                this.$store.commit('cities/SET_ACTIVE_CITY_ID', item.id);
                this.$store.commit('cities/SET_CITY_DATA', item);
            }
            this.$store.commit('cities/SET_CITY_FORM_VISIBILITY', true);
        },
        deleteCity (id) {
            return this.$store.dispatch('cities/deleteCity', id)
                .then(() => {
                    this.$emit('fetch');
                });
        }
    },
    mounted () {
        this.$set(this.sortable, `${this.countryName}`, null);
    }
};
</script>

<style scoped>

</style>
